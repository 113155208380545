import { classed } from '@tw-classed/react';

export const SkeletonButton = classed('div', 'rounded-lg animate-pulse', {
  variants: {
    size: {
      lg: 'h-12',
      md: 'h-10',
      sm: 'h-8',
      xs: 'h-6',
      none: '',
      full: 'w-full',
    },
    color: {
      default: 'bg-muted',
      primary: 'bg-primary',
      error: 'bg-destructive',
      success: 'bg-success',
      warning: 'bg-warning',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'default',
  },
});
