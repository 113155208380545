'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';

import { cn } from '~/lib';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipBase = (props: React.PropsWithChildren) => (
  <TooltipProvider>
    <TooltipRoot>{props.children}</TooltipRoot>
  </TooltipProvider>
);

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { TooltipBase, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger };

export const Tooltip = ({
  children,
  title,
  delayDuration = 200,
  className,
}: React.PropsWithChildren<{
  title: string;
  delayDuration?: number;
  className?: string;
}>) => {
  return (
    <TooltipProvider>
      <TooltipRoot delayDuration={delayDuration}>
        <TooltipTrigger asChild={typeof children !== 'string'}>
          {children}
        </TooltipTrigger>
        <TooltipContent className={cn('not-prose max-w-md', className)}>
          <p className="text-center">{title}</p>
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
};
