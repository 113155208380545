import { useActiveCode, useSandpack } from '@codesandbox/sandpack-react';
import clsx from 'clsx';
import {
  Copy,
  Maximize2,
  Minimize2,
  Paintbrush,
  RotateCcw,
  Trash2,
} from 'lucide-react';

// @ts-expect-error - No types
import type { CodeMirrorRef } from '@codesandbox/sandpack-react/components/CodeEditor/CodeMirror';
import estreePlugin from 'prettier/plugins/estree';
import typescriptPlugin from 'prettier/plugins/typescript';
import prettier from 'prettier/standalone';
import type { RefObject } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Tooltip } from '~/components/ui/tooltip';
import styles from './SandPackPlaygroundPrettierButton.module.scss';

export const SandPackPlaygroundResetButton = () => {
  const { sandpack } = useSandpack();

  const onReset = () => {
    sandpack.resetAllFiles();
  };

  return (
    <Tooltip title="Reset">
      <button onClick={onReset} className={clsx('rounded-md p-1', styles.button)}>
        <RotateCcw
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandPackPlaygroundPrettierButton = ({
  codemirrorInstance,
}: {
  codemirrorInstance: RefObject<CodeMirrorRef>;
}) => {
  const { sandpack } = useSandpack();
  const activeCode = useActiveCode();

  const onPrettier = async () => {
    const formatted = await prettier.format(activeCode.code, {
      parser: 'typescript',
      plugins: [typescriptPlugin, estreePlugin],
    });
    const cmInstance = codemirrorInstance.current?.getCodemirror();

    if (!cmInstance) {
      return;
    }
    const trans = cmInstance.state.update({
      selection: cmInstance.state.selection,
      changes: {
        from: 0,
        to: cmInstance.state.doc.length,
        insert: formatted,
      },
    });

    cmInstance.update([trans]);

    sandpack.updateFile(sandpack.activeFile, formatted);
  };

  return (
    <Tooltip title="Prettier">
      <button onClick={onPrettier} className={clsx('rounded-md p-1', styles.button)}>
        <Paintbrush
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackPlaygroundResizeButton = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  const isDesktop = useMediaQuery('(min-width: 800px');

  if (!isDesktop) {
    return null;
  }

  return (
    <Tooltip title="Expand">
      <button onClick={onClick} className={clsx('rounded-md p-1', styles.button)}>
        {enabled ? (
          <Minimize2
            style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
            className="relative size-4"
          />
        ) : (
          <Maximize2
            style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
            className="relative size-4"
          />
        )}
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleRefresh = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip title="Refresh and delete all logs.">
      <button onClick={onClick} className={clsx('rounded-md p-1', styles.button)}>
        <Trash2
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleDisableDuplication = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  return (
    <Tooltip title="StrictMode may duplicate all logs. If enabled, we-will disable duplication.">
      <button
        onClick={onClick}
        className={clsx('rounded-md p-1', styles.button, {
          [styles.enabled]: enabled,
        })}
      >
        <Copy
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};

export const SandpackConsoleResetOnPreviewRestart = ({
  onClick,
  enabled,
}: {
  onClick: () => void;
  enabled: boolean;
}) => {
  return (
    <Tooltip title="If enabled, when the preview is updated the log will be destroyed.">
      <button
        onClick={onClick}
        className={clsx('rounded-md p-1', styles.button, {
          [styles.enabled]: enabled,
        })}
      >
        <RotateCcw
          style={{ color: 'var(--sp-colors-clickable)', left: 1 }}
          className="relative size-4"
        />
      </button>
    </Tooltip>
  );
};
