import {
  SandpackPreview,
  SandpackStack,
  SandpackTests,
} from '@codesandbox/sandpack-react';

import { SplitPane } from '~/components/lib/splite-pane/SplitPane';
import type { PlaygroundModeType } from './MdxPlaygroundPropsSchema';
import { SandpackPlaygroundConsoleAndPreview } from './SandPackPlaygroundConsoleAndPreview';
import { SandpackConsoleStyled } from './SandpackConsoleStyled';

type SecondPaneProps = {
  isTest: boolean;
  mode: PlaygroundModeType;
  defaultTab: 'console' | 'preview';
  showNavigator?: boolean;
};

export const SandpackSecondPane = ({
  isTest,
  defaultTab,
  showNavigator,
  mode,
}: SecondPaneProps) => {
  if (isTest) {
    if (mode === 'both') {
      return (
        <SandpackStack className="h-full">
          <SplitPane direction="vertical" defaultFirstRatio={0.6}>
            <SandpackTests style={{ height: '100%' }} />
            <SandpackConsoleStyled />
          </SplitPane>
        </SandpackStack>
      );
    }

    if (mode === 'console') {
      return (
        <SandpackStack className="h-full">
          <div className="hidden">
            <SandpackTests style={{ height: '0px' }} />
          </div>
          <SandpackConsoleStyled />
        </SandpackStack>
      );
    }

    return <SandpackTests style={{ height: '100%' }} />;
  }

  if (mode === 'both-tabs') {
    return <SandpackPlaygroundConsoleAndPreview defaultTab={defaultTab} />;
  }

  const sandpackPreviewProps = {
    showNavigator,
  };

  if (mode === 'console') {
    return (
      <SandpackStack className="h-full">
        <SandpackConsoleStyled className="h-full" />
        <SandpackPreview {...sandpackPreviewProps} style={{ display: 'none' }} />
      </SandpackStack>
    );
  }

  if (mode === 'both') {
    return (
      <SandpackStack className="h-full">
        <SplitPane direction="vertical" defaultFirstRatio={0.6}>
          <SandpackPreview
            {...sandpackPreviewProps}
            style={{ height: '100%' }}
            className="overflow-hidden rounded-b-md"
          />
          <SandpackConsoleStyled rounded />
        </SplitPane>
      </SandpackStack>
    );
  }

  return <SandpackPreview {...sandpackPreviewProps} style={{ height: '100%' }} />;
};
