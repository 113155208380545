/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import React from 'react';
import styles from './Typography.module.scss';

export type TypographyColor =
  | 'error'
  | 'invert'
  | 'none'
  | 'textPrimary'
  | 'textQuaternary'
  | 'textSecondary'
  | 'textTertiary';

export type TypographyVariant = 'body1' | 'body2' | 'body3' | 'h1' | 'h2' | 'h3';

type TypographyProps<TComponent extends React.ComponentType<any>> =
  PropsWithChildren<{
    variant?: TypographyVariant;
    multiline?: boolean;
    as?: TComponent | string;
    color?: TypographyColor;
    className?: string;
    asProps?: React.ComponentProps<TComponent>;
    bold?: boolean;
    italic?: boolean;
    align?: 'center' | 'left' | 'right';
    lineThrough?: boolean;
    underline?: boolean;
    fullWidth?: boolean;
    ellipsis?: boolean;
    style?: ComponentPropsWithoutRef<'p'>['style'];
  }>;

const getComponent = (variant: TypographyProps<never>['variant']) => {
  switch (variant) {
    case 'body1':
      return 'p';
    case 'body2':
      return 'p';
    case 'body3':
      return 'p';
    default:
      return variant;
  }
};

/**
 * Typography is a component to harmonize the typography of the application.
 *
 * @param variant "h2" | "h3" | "body1" | "body2"
 * @param component Override the component to use
 * @param color "primary" | "secondary"
 * @param children Text to display
 * @param className Class name of the component
 * @param props All other props for the text
 * @returns {JSX.Element}
 * @constructor
 */
export const Typography = <TComponent extends React.ComponentType<any>>({
  variant = 'body1',
  as,
  color = 'textPrimary',
  children,
  className,
  asProps,
  italic,
  bold,
  align,
  multiline,
  lineThrough,
  underline,
  fullWidth,
  style,
  ellipsis,
  ...props
}: TypographyProps<TComponent>) => {
  const propsToPass =
    typeof asProps === 'object'
      ? {
          ...props,
          ...asProps,
        }
      : props;

  const textColor = getTextColor(color);

  const classes = clsx(styles.typography, styles[variant], textColor, className, {
    'font-bold': bold,
    italic,
    'text-left': align === 'left',
    'text-center': align === 'center',
    'text-right': align === 'right',
    'line-through': lineThrough,
    underline,
    'w-full': fullWidth,
    'overflow-hidden text-ellipsis whitespace-nowrap': ellipsis,
  });

  const Component = as ?? getComponent(variant);

  if (!Component) {
    return <p>error</p>;
  }

  const splitChildren = multiline ? children?.toString().split('\n') : null;
  if (splitChildren) {
    return (
      <>
        {splitChildren.map((child, index) => (
          <Component key={index} className={classes} {...propsToPass} style={style}>
            {child}
          </Component>
        ))}
      </>
    );
  }

  return (
    <Component className={classes} {...propsToPass} style={style}>
      {children}
    </Component>
  );
};

const getTextColor = (color: TypographyProps<never>['color']) => {
  switch (color) {
    case 'textPrimary':
      return 'text-foreground';
    case 'textSecondary':
      return 'text-foreground';
    case 'textTertiary':
      return 'text-muted-foreground';
    case 'textQuaternary':
      return 'text-muted-foreground';
    case 'error':
      return 'text-error-foreground';
    case 'invert':
      return 'text-background';
    case 'none':
      return '';
    default:
      return color;
  }
};
