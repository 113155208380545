'use client';

import type { MuxPlayerProps } from '@mux/mux-player-react';
import MuxPlayer from '@mux/mux-player-react';
import type { ComponentRef } from 'react';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { Typo } from '~/components/atoms/typography/Typo';
import { AspectRatio } from '~/components/ui/aspect-ratio';
import {
  CinemaViewer,
  CinemaViewerActions,
  CinemaViewerContainer,
  CinemaViewerToggle,
} from '~/features/cinema/CinemaViewer';

export type ValidChapter = {
  startTime: number;
  value: string;
};

type MuxVideoProps = {
  id: string;
  poster?: string;
  title?: string;
  className?: string;
  autoPlay?: boolean;
  chapters?: ValidChapter[];
};

// Add chapters
export default function MuxVideo({
  id,
  poster,
  title,
  autoPlay,
  chapters,
}: MuxVideoProps) {
  return (
    <CinemaViewer>
      <CinemaViewerContainer>
        <CinemaViewerActions>
          <Typo variant="muted">{title ?? 'Video'}</Typo>
          <div className="flex-1"></div>

          <CinemaViewerToggle />
        </CinemaViewerActions>
        <AspectRatio ratio={16 / 9}>
          <MuxPlayerCustom
            style={{
              width: '100%',
            }}
            theme=""
            streamType="on-demand"
            poster={poster}
            playbackId={id}
            accentColor="hsl(var(--primary))"
            autoPlay={autoPlay}
            chapters={chapters}
          />
        </AspectRatio>
      </CinemaViewerContainer>
    </CinemaViewer>
  );
}

export const MuxPlayerCustom = (
  props: MuxPlayerProps & {
    chapters?: ValidChapter[];
  }
) => {
  const [savedRate, setSavedRate] = useLocalStorage('MuxPlayerCustom-mux-rate', 1);
  const muxRef = useRef<ComponentRef<typeof MuxPlayer>>(null);

  useEffect(() => {
    if (muxRef.current && props.chapters) {
      muxRef.current.addChapters(
        props.chapters as unknown as {
          startTime: number;
          endTime: number;
          value: string;
        }[]
      );
    }
  }, [props.chapters]);

  return (
    <MuxPlayer
      {...props}
      ref={muxRef}
      playbackRates={[0.7, 0.9, 1, 1.25, 1.5, 1.75, 2, 2.5]}
      playbackRate={savedRate}
      onRateChange={() => {
        const newRate = muxRef.current?.playbackRate ?? 1;
        setSavedRate(newRate);
      }}
    />
  );
};
