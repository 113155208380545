import { Fragment, useEffect, useRef, type ComponentPropsWithoutRef } from 'react';

import { useSandpackConsole } from '@codesandbox/sandpack-react';
import clsx from 'clsx';
import { useLocalStorage } from 'usehooks-ts';
import {
  SandpackConsoleDisableDuplication,
  SandpackConsoleRefresh,
  SandpackConsoleResetOnPreviewRestart,
} from './SandPackPlaygroundButtons';
import styles from './SandpackConsoleStyled.module.scss';

type SandpackConsoleStyledProps = ComponentPropsWithoutRef<'div'> & {
  rounded?: boolean;
};

const countSameLog = (logMap: string[], logData: string) => {
  let count = 0;

  for (const v of logMap) {
    if (v === logData) {
      count++;
    }
  }

  return count;
};

const removeDuplicateLogs = (logs: SandpackConsoleData): SandpackConsoleData => {
  const uniqueLogs: SandpackConsoleData = [];
  const logsByData: string[] = [];

  for (const log of logs) {
    if (
      log.data
        ?.join(' ')
        .includes('cdn.tailwindcss.com should not be used in production.') ??
      log.data
        ?.join(' ')
        .includes(
          'warn - No utility classes were detected in your source files. If this is unexpected, double-check the `content` option in your Tailwind CSS configuration.'
        ) ??
      log.data
        ?.join(' ')
        .includes('warn - https://tailwindcss.com/docs/content-configuration')
    ) {
      continue;
    }

    const logData = JSON.stringify(log.data);
    const count = countSameLog(logsByData, logData);

    if (count % 2 === 0) {
      uniqueLogs.push(log);
    }
    logsByData.push(logData);
  }

  return uniqueLogs;
};

type SandpackConsoleData = ReturnType<typeof useSandpackConsole>['logs'];

const removeServiceWorkLog = (logs: SandpackConsoleData): SandpackConsoleData => {
  let tempLogs = logs.filter(
    (log) => !log.data?.join(' ').includes('Service Worker registration failed:')
  );

  tempLogs = tempLogs.filter((log) => {
    return !log.data?.join(' ').startsWith('%c%s');
  });

  tempLogs = tempLogs.filter((log) => {
    return !log.data?.join(' ').startsWith('%c%i');
  });

  return tempLogs;
};

export const SandpackConsoleStyled = ({
  rounded,
  ...props
}: SandpackConsoleStyledProps) => {
  const [disableDuplication, setDisableDuplication] = useLocalStorage(
    'SandpackConsoleStyled-disableDuplication',
    true
  );
  const [resetOnPreviewRestart, setResetOnPreviewRestart] = useLocalStorage(
    'SandpackConsoleStyled-resetOnPreviewRestart',
    true
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const { logs, reset } = useSandpackConsole({
    resetOnPreviewRestart,
  });

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [logs]);

  const preferredLogs = removeServiceWorkLog(
    disableDuplication ? removeDuplicateLogs(logs) : logs
  );

  return (
    <>
      <div
        {...props}
        ref={containerRef}
        className={clsx('not-prose relative h-full overflow-auto', {
          'rounded-md': rounded,
        })}
        style={{ background: 'var(--sp-colors-surface1)', ...props.style }}
      >
        <ul className=" flex w-full flex-col gap-1">
          {preferredLogs.map((log, i) => {
            if (!log.data) return null;
            if (log.data.length === 0) return null;

            return (
              <Fragment key={log.id}>
                <li
                  className={clsx(
                    'break-words p-1 text-xs text-foreground',
                    styles.log,
                    {
                      [styles.warn]: log.method === 'warn',
                      [styles.error]: log.method === 'error',
                    }
                  )}
                >
                  {log.data
                    .map((l) => stringifyLine(l, true))
                    .map((l, y) => {
                      if (
                        log.method === 'error' &&
                        typeof l === 'string' &&
                        l.includes('at $csb$eval')
                      ) {
                        l = l.split('at $csb$eval')[0].trim();
                      }
                      return (
                        <code
                          className="whitespace-pre-wrap text-foreground first-of-type:mr-1"
                          key={y}
                        >
                          {l}
                        </code>
                      );
                    })}
                </li>

                {i !== logs.length - 1 && (
                  <hr
                    className="w-full"
                    style={{ borderColor: 'var(--sp-colors-surface3)', height: 2 }}
                  />
                )}
              </Fragment>
            );
          })}
          {preferredLogs.length === 0 && (
            <li className="p-1 text-xs text-gray-500">
              <code>No logs</code>
            </li>
          )}
        </ul>
      </div>
      <div
        className="absolute bottom-1 right-1 ml-auto flex w-fit gap-1"
        style={{ height: 28 }}
      >
        <SandpackConsoleDisableDuplication
          enabled={disableDuplication}
          onClick={() => setDisableDuplication((p) => !p)}
        />
        <SandpackConsoleResetOnPreviewRestart
          enabled={resetOnPreviewRestart}
          onClick={() => setResetOnPreviewRestart((p) => !p)}
        />
        <SandpackConsoleRefresh onClick={() => reset()} />
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringifyLine = (obj: any, isFirst = false): any => {
  if (typeof obj === 'string') {
    return obj;
  }

  if (typeof obj === 'number') {
    return obj;
  }

  if (typeof obj === 'boolean') {
    return String(obj);
  }

  if (typeof obj === 'object' && obj !== null) {
    // go on each key, and recursively stringify
    if (typeof obj['@t'] === 'string') {
      if (obj['@t'] === '[[undefined]]') {
        return 'undefined';
      }

      if (obj.data) {
        return JSON.stringify(obj.data, null, 2);
      }

      return JSON.stringify(obj, null, 2);
    }

    for (const key in obj) {
      obj[key] = stringifyLine(obj[key]);
    }
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = stringifyLine(obj[i]);
    }
  }

  if (isFirst) {
    return JSON.stringify(obj, null, 2);
  }

  return obj;
};
